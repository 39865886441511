import React, { useContext, useState, useEffect } from 'react'
import { firestore } from './firebase'
import { isValidHttpUrl } from '../utils/Utils'

const ArticlesContext = React.createContext()

export function useArticles() {
  return useContext(ArticlesContext)
}

const ArticlesContextProvider = (props) => {

  const [articles, setArticles] = useState([])

  // async function getArticles() {
  //   const events = await firestore.collection('articles')
  //   events.get().then((querySnapshot) => {
  //     const tempDoc = []
  //     querySnapshot.forEach((doc) => {
  //       if (validArticle(doc)) {
  //         tempDoc.push({ id: doc.id, ...doc.data() })
  //       }
  //     })
  //     setArticles(tempDoc)
  //   })
  // }

  // var unsubscribe

  useEffect(() => {
    var unsubscribe = firestore.collection('articles2').onSnapshot((snapshot) => {
      const tempDoc = []
      snapshot.forEach((doc) => {
        if (validArticle(doc)) {
          tempDoc.push({ id: doc.id, ...doc.data() })
        }
      })
      setArticles(tempDoc)
    })
    return () => {
      // cleanup
      unsubscribe()
    }
  }, [])

  return (
    <ArticlesContext.Provider value={{ articles }}>
      {props.children}
    </ArticlesContext.Provider>
  )

}
export default ArticlesContextProvider


function validArticle(doc) {
  const data = doc.data()
  if (data === undefined || data === null) return false
  if (typeof data.publisher !== typeof 'string') return false
  if (typeof data.headline !== typeof 'string') return false
  if (!isValidHttpUrl(data.link)) return false
  return true
}
