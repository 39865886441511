export function contains_hebrew(str) {
  return (/[\u0590-\u05FF]/).test(str);
}


export function isValidHttpUrl(string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}