import React, { createContext, useState } from 'react'
import {reactLocalStorage} from 'reactjs-localstorage';

import JsonData_He from '../data/data_he.json'
import JsonData_En from '../data/data_en.json'

export const DataContext = createContext()

const DataContextProvider = (props) => {
  const LANGUAGE_KEY = 'language'
  const language = reactLocalStorage.get(LANGUAGE_KEY, 'he', true)

  var jsonDataFile = {}
  if (language === 'he'){
    jsonDataFile = JsonData_He
    setDirection('rtl')
  } else {
    jsonDataFile = JsonData_En
    setDirection('ltr')
  }

  const [data, setData] = useState(jsonDataFile)
  const [headerData, setHeaderData] = useState(data.header)
  const [sliderData, setSliderData] = useState(data.slider)
  const [servicesData, setServicesData] = useState(data.services)
  const [aboutData, setAboutData] = useState(data.about)
  const [productsData, setProductsData] = useState(data.products)
  const [serviceData, setServiceData] = useState(data.service)
  const [teamData, setTeamData] = useState(data.team)
  const [publicationsData, setPublicationsData] = useState(data.publications)
  const [contactData, setContactData] = useState(data.contact)
  const [footerData, setFooterData] = useState(data.footer)

  // changeLanguage(language)

  function changeLanguage(lang) {
    console.log('changeLanguage is called')
    reactLocalStorage.set(LANGUAGE_KEY, lang)
    
    switch (lang) {
      case 'en':
        setData(JsonData_En)
        setSubData(JsonData_En)
        setDirection('ltr')
        break;
      case 'he':
        setData(JsonData_He)
        setSubData(JsonData_He)
        setDirection('rtl')
        break;
      default:
        console.log('error, not a valid language:', lang)
    }
    window.location.reload();
  }

  function setSubData(currentData) {
    setHeaderData(currentData.header)
    setSliderData(currentData.slider)
    setServicesData(currentData.services)
    setAboutData(currentData.about)
    setProductsData(currentData.products)
    setServiceData(currentData.service)
    setTeamData(currentData.team)
    setPublicationsData(currentData.publications)
    setContactData(currentData.contact)
    setFooterData(currentData.footer)
  }

  function setDirection(dir) {
    const ele1 = document.getElementById('style-id-attr')
    const ele2 = document.getElementById('bootstrap-id-attr')
    if (dir === 'ltr') {
      ele1.setAttribute('href', 'css/layout.css')
      ele2.setAttribute('href', 'vendor/bootstrap/css/bootstrap.css')
      document.body.setAttribute('dir', 'ltr')
    } else {
      // Hebrew & Arabic
      ele1.setAttribute('href', 'css/layout-he.css')
      ele2.setAttribute('href', 'vendor/bootstrap/css/bootstrap-he.css')
      document.body.setAttribute('dir', 'rtl')
    }
  }

  return (
    <DataContext.Provider value=
      {{ headerData, sliderData, servicesData, aboutData, productsData, serviceData, teamData, publicationsData, contactData, footerData, changeLanguage }}>
      {props.children}
    </DataContext.Provider>
  )
}

export default DataContextProvider