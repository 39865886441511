import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { contains_hebrew } from '../utils/Utils'
import { useArticles } from '../contexts/ArticlesContext';

export default function Publications() {
  const { publicationsData } = useContext(DataContext)
  const { articles } = useArticles()

  return (

    <section id="publications">
      <div className="section-seperator">

        {/* <!-- Container --> */}
        <div className="content-lg container">

          <div className="row margin-b-40">
            <div className="col-sm-6">
              <h2>{publicationsData.title}</h2>
              <p>{publicationsData.text}</p>
            </div>
          </div>

          <Scrollers list={articles} />

        </div>
        {/* <!-- End Container --> */}
      </div>
    </section>
  )
}

// const Arrow = ({ text, className }) => {
//   return <div className={className}>{text}</div>;
// };

// const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
// const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

const Scrollers = (({ list }) => {
  const Publications = articlesList(list)
  return (
    <div dir='ltr'>
      <div className='site-template'>
        <ScrollMenu
          className='container'
          data={Publications[0]}
          // arrowLeft={ArrowLeft}
          // arrowRight={ArrowRight}
          // translate={-1}
        // selected={selected}
        // onSelect={itemSelected}
        wheel={false}
        />
      </div>

      <div className='site-template'>
        <ScrollMenu
          className='container'
          data={Publications[1]}
          // translate={-1}
        // arrowLeft={ArrowLeft}
        // arrowRight={ArrowRight}
        // selected={selected}
        // onSelect={itemSelected}
        wheel={false}
        />
      </div>

    </div>
  )
})


/*
  takes the json list split to 2 lists
  build a React Elements list from each list
*/
const articlesList = (list => {

  const numOfElements = list.length
  const list1 = list.slice(0, numOfElements / 2)
  const list2 = list.slice(numOfElements / 2, numOfElements)

  const list1Elements = list1.map((ele, index) => {
    const { type, publisher, headline, link, img } = ele;
    if (type === 'bdicode') {
      return <BDiItem key={index} publisher={publisher} headline={headline} link={link} img={img} />
    } else {
      return <Item key={index} publisher={publisher} headline={headline} link={link} img={img} />
    }
  })

  const list2Elements = list2.map((ele, index) => {
    const { type, publisher, headline, link, img } = ele;
    if (type === 'bdicode') {
      return <BDiItem key={index} publisher={publisher} headline={headline} link={link} img={img} />
    } else {
      return <Item key={index + numOfElements / 2} publisher={publisher} headline={headline} link={link} img={img} />;
    }
  })

  return ([list1Elements, list2Elements])
}
)

const Item = ({ publisher, headline, link, img }) => {
  const direction = contains_hebrew(headline) ? 'rtl' : 'ltr'
  return (
    <div className='scroller-item' style={{ backgroundImage: `url(${img})` }}>
      <div className='transbox'>
        <div className='layout'>
          <div className='publisher'>
            <div className='publisher__text'>{publisher}</div>
          </div>
          <a className='headline' style={{ direction: direction }} href={link}>
            <div className='headline__text'>
              {headline}
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

const BDiItem = ({ publisher, headline, link, img }) => {
  return (
    <div className='scroller-item scroller-item__one' style={{ backgroundImage: `url(${img})` }}>
      <div className='layout' >
        <div className='bdicode__publisher'>
          <div className='bdicode__publisher__text'>{publisher}</div>
        </div>
        <a className='bdicode__headline' href={link}>
          <div className='bdicode__headline__text'>{headline}</div>
        </a>
      </div>
    </div>
  )
}