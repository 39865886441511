import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'

export default function Team() {
    const { teamData } = useContext(DataContext)
    return (
        <section id="work">
            <div className="section-seperator">
                <div className="content-md container">
                    <div className="row margin-b-40">
                        <div className="col-sm-6">
                            <h2>{teamData.title}</h2>
                            <p>{teamData.text}</p>
                        </div>
                    </div>
                    {/* <!--// end row --> */}

                    {/* <!-- Masonry Grid --> */}
                    <div className="masonry-grid row">
                        <div className="masonry-grid-sizer col-xs-6 col-sm-6 col-md-1 col-lg-1"></div>

                        {teamData.items.map( (item, index) => {
                            if (item.type === "profile") {
                                return <Profile data={item} key={index}/>
                            } else if (item.type === "office") {
                                return <Office data={item} key={index}/>
                            } else {
                                return null
                            }
                        })}

                    </div>
                    {/* <!-- End Masonry Grid --> */}
                </div>
            </div>

        </section>
    )
}


const Profile = (props) => {
    return (
        <div className="masonry-grid-item col-xs-12 col-sm-6 col-md-4 col-lg-4 margin-b-30">
            {/* <!-- Work --> */}
            <div className="work work-popup-trigger">
                <div className="work-overlay">
                    <img className="full-width img-responsive" src={props.data.img} alt={props.data.alt} />
                </div>
                <div className="work-popup-overlay">
                    <div className="work-popup-content">
                        <a href="javascript:void(0);" className="work-popup-close">Hide</a>
                        <div className="margin-b-30">
                            <h3 className="margin-b-5">{props.data.name}</h3>
                            <span>{props.data.professionalTitle}</span>
                        </div>
                        <div className="row">
                            <div className="col-sm-8 work-popup-content-divider sm-margin-b-20">
                                <div className="margin-t-10 sm-margin-t-0">
                                    {props.data.about.map((text,index) =>
                                        <p key={index}>{text}</p>
                                    )}
                                    <ul className="list-inline work-popup-tag">
                                        <li key='linkedin' className="work-popup-tag-item"><a className="work-popup-tag-link" href={props.data.linkedInLink}>{props.data.linkedIn}</a></li>
                                        <li key='email' className="work-popup-tag-item"><a className="work-popup-tag-link" href={props.data.emailLink}>{props.data.email}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="margin-t-10 sm-margin-t-0">
                                    {props.data.highlights.map((highlight, index) =>
                                        <p key={index} className="margin-b-5"><strong>{highlight.degree}</strong>{highlight.institution}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- End Work --> */}
        </div>
    )
}

const Office = (props) => {
    return (
        <div className="masonry-grid-item col-xs-12 col-sm-6 col-md-8 col-lg-8 margin-b-30">
            {/* <!-- Work --> */}
            <div className="work work-popup-trigger">
                <div className="">
                    <img className="full-width img-responsive" src={props.data.img} alt={props.data.alt} />
                </div>
            </div>
            {/* <!-- End Work --> */}
        </div>
    )
}