import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'

export default function Services() {
  const { servicesData } = useContext(DataContext)

  return (
    <section id="services">
      <div className="content-lg container">
        {/* <!-- Masonry Grid --> */}
        <div className="masonry-grid row">
          <div className="masonry-grid-sizer col-xs-6 col-sm-6 col-md-1"></div>

          {servicesData.experties.map((content, index) => {
            if (index % 2 === 0) {
              return <PictureBottom key={index} content={content} index={index} />;
            } else {
              return <PictureTop key={index} content={content} index={index} />;
            }
          })}

        </div>
        {/* <!-- End Masonry Grid --> */}
      </div>
    </section>
  )
}

const PictureBottom = (props) => {
  //  return <ExpandedAcordionItem content={props.content} index={props.index} />
  const content = props.content
  return (
    <div className="masonry-grid-item col-xs-12 col-sm-6 col-md-6 sm-margin-b-30 margin-b-60">
      <div className="margin-b-60">
        <h2>{content.title}</h2>
        <ExpertiesText text={content.text} />
      </div>
      <img className="full-width img-responsive wow fadeInUp" src={content.img} alt={content.alt} data-wow-duration=".3" data-wow-delay=".2s" />
    </div>
  )
}

const PictureTop = (props) => {
  //  return <ExpandedAcordionItem content={props.content} index={props.index} />
  const content = props.content
  return (
    <div className="masonry-grid-item col-xs-12 col-sm-6 col-md-6 margin-b-60" >
      <div className="margin-t-60 margin-b-60">


        <h2>{content.title}</h2>
        <img className="full-width img-responsive wow fadeInUp margin-b-30" src={content.img} alt={content.alt} data-wow-duration=".3" data-wow-delay=".3s" />
        <ExpertiesText text={content.text} />
      </div>
    </div>
  )
}

const ExpertiesText = (props) => {
  return (
    props.text.map((t, index) => {
      return <p key={index}>{t}</p>
    })
  )
}

// const ExpertiesText = (props) => {
//   return (
//     props.text.map((paragraph, index) => {
//       if (paragraph.txt != null) {
//         return <p>{paragraph.txt}</p>
//       }
//       if (paragraph.list != null) {
//         return (
//           <ul>
//             {paragraph.list.map((item, index) => {
//               return <li>item</li>
//             })}
//           </ul>
//         )
//       }
//     })
//   )
// }
