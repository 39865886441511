import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'


export default function About() {
    const { aboutData } = useContext(DataContext)
    return (
        <section id="about">
            <div className="bg-color-sky-light">
                <div className="content-lg container">
                    <div className="row">
                        <div className="col-md-5 col-sm-5 md-margin-b-60">
                            <div className="margin-t-50 margin-b-30">
                                <h2>{aboutData.aboutTitle}</h2>
                                <p>{aboutData.aboutText1}</p>
                                <p>{aboutData.aboutText2}</p>
                            </div>
                            {/* <a href="#" className="btn-theme btn-theme-sm btn-white-bg text-uppercase">Explore</a> */}
                        </div>
                        <div className="col-md-5 col-sm-7 col-md-offset-2">
                            {/* <!-- Accordion --> */}
                            <div className="accordion">
                                <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                                    {aboutData.principles.map((content, index) => {
                                        return <AcordionItem key={index} content={content} index={index} />
                                    })}

                                </div>
                            </div>
                            {/* <!-- End Accodrion --> */}
                        </div>
                    </div>
                    {/* <!--// end row --> */}
                </div>
            </div>
        </section>

    )
}

const AcordionItem = (props) => {
    if (props.index === 0) {
        return <ExpandedAcordionItem content={props.content} index={props.index} />
    } else {
        return <CollapsedAcordionItem content={props.content} index={props.index} />
    }
}

const ExpandedAcordionItem = (props) => (
    <div className="panel panel-default">
        <div className="panel-heading" role="tab" id={'heading' + props.index}>
            <h4 className="panel-title">
                <a className="panel-title-child" role="button" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + props.index} aria-expanded="true" aria-controls={'collapse' + props.index}>
                    {props.content.title}
                </a>
            </h4>
        </div>
        <div id={'collapse' + props.index} className="panel-collapse collapse in" role="tabpanel" aria-labelledby={'heading' + props.index}>
            <div className="panel-body">
                {props.content.text}
            </div>
        </div>
    </div>
)

const CollapsedAcordionItem = (props) => (
    <div className="panel panel-default">
        <div className="panel-heading" role="tab" id={'heading' + props.index}>
            <h4 className="panel-title">
                <a className="collapsed panel-title-child" role="button" data-toggle="collapse" data-parent="#accordion" href={'#collapse' + props.index} aria-expanded="false" aria-controls={'collapse' + props.index}>
                    {props.content.title}
                </a>
            </h4>
        </div>
        <div id={'collapse' + props.index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={'heading' + props.index}>
            <div className="panel-body">
                {props.content.text}
            </div>
        </div>
    </div>
)




