import React, {useContext} from 'react'
import { DataContext } from '../contexts/DataContext'

export default function Header() {
  const { headerData, changeLanguage } = useContext(DataContext)

  return (
    <header className="header navbar-fixed-top">
      {/* <!-- Navbar --> */}
      <nav className="navbar" role="navigation">
        <div className="container">
          {/* <!-- Brand and toggle get grouped for better mobile display --> */}
          <div className="menu-container js_nav-item">
            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".nav-collapse">
              <span className="sr-only">Toggle navigation</span>
              <span className="toggle-icon"></span>
            </button>

            {/* <!-- Logo --> */}
            <div className="logo">
              <a className="logo-wrap" href="#body">
                <embed className="logo-img logo-img-main" src={headerData.logo.logoLong} alt="SHCO Logo" />
                <embed className="logo-img logo-img-active" src={headerData.logo.logoShort} alt="SHCO Logo" />
              </a>
            </div>
            {/* <!-- End Logo --> */}
          </div>

          {/* <!-- Collect the nav links, forms, and other content for toggling --> */}
          <div className="collapse navbar-collapse nav-collapse">
            <div className="menu-container">
              <ul className="nav navbar-nav navbar-nav-right">
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#body">{headerData.navigation.home}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#services">{headerData.navigation.services}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#about">{headerData.navigation.about}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#work">{headerData.navigation.work}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#publications">{headerData.navigation.publications}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="#contact">{headerData.navigation.contact}</a></li>
                <li className="js_nav-item nav-item"><a className="nav-item-child nav-item-hover" href="javascript:void(0);" onClick={()=>changeLanguage(headerData.languageArg)}>{headerData.navigation.language}</a></li>
              </ul>
            </div>
          </div>
          {/* <!-- End Navbar Collapse --> */}
        </div>
      </nav>
      {/* <!-- Navbar --> */}
    </header>
  )
}
