import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'

const Slider = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const breakpoint = 992;

  React.useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return width < breakpoint ? <MobileSlider /> : <DesktopSlider />;
}

export default Slider;

function DesktopSlider() {
  const { sliderData } = useContext(DataContext)

  return (
    <div>

      <section id="carousel-example-generic" className="carousel fade" data-ride="carousel" data-interval="10000">

        {/* <div className="container">
        <!-- Indicators -->
        <ol className="carousel-indicators">
          <li data-target="#carousel-example-generic" data-slide-to="0" className="active"></li>
          <li data-target="#carousel-example-generic" data-slide-to="1"></li>
        </ol>
      </div> */}

        {/* <!-- Wrapper for slides --> */}
        <div className="carousel-inner" role="listbox">

          <div className="item active">
            <img className="" src={sliderData.images[0].img} alt={sliderData.images[0].alt} />
            <div className="container">
              <div className="carousel-bottom">
                <h1 className="carousel-title">{sliderData.title}</h1>
                <hr></hr>
                <h2 className="carousel-subtitle">{sliderData.subtitle}</h2>
              </div>
            </div>
          </div>

          <div className="item">
            <img className="" src={sliderData.images[1].img} alt={sliderData.images[1].alt} />
            <div className="container">
              <div className="carousel-bottom">
                <h1 className="carousel-title">{sliderData.title}</h1>
                <hr></hr>
                <h2 className="carousel-subtitle">{sliderData.subtitle}</h2>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div className="container">
        <BDiCode />
      </div>

    </div>
  )
}

function MobileSlider() {
  const { sliderData } = useContext(DataContext)

  return (
    <div>

      <section id="carousel-example-generic" className="carousel fade" data-ride="carousel" data-interval="5000">       

        {/* <!-- Wrapper for slides --> */}
        <div className="carousel-inner" role="listbox">

          <div className="item active">
            <img className="" src={sliderData.imagesMobile[0].img} alt={sliderData.imagesMobile[0].alt} />
            <div className="container">
              <div className="carousel-bottom">
                <h1 className="carousel-title">{sliderData.title}</h1>
                <hr></hr>
                <h2 className="carousel-subtitle">{sliderData.subtitle}</h2>
              </div>
            </div>
          </div>

          <div className="item">
            <img className="" src={sliderData.imagesMobile[1].img} alt={sliderData.imagesMobile[1].alt} />
            <div className="container">
              <div className="carousel-bottom">
                <h1 className="carousel-title">{sliderData.title}</h1>
                <hr></hr>
                <h2 className="carousel-subtitle">{sliderData.subtitle}</h2>
              </div>
            </div>
          </div>

        </div>
      </section>
      <div className="container">
        <BDiCode />
      </div>

    </div>
  )
}

function BDiCode() {
  return (

    <div className="carousel-bdicode">
      <a href="https://www.bdicode.co.il/company/%d7%a1%d7%97%d7%90%d7%99-%d7%94%d7%a8%d7%90%d7%9c-%d7%95%d7%a9%d7%95%d7%aa/">
        <img className="bdicode" src="img/slider/badge.png" alt="BDi Code leading companies"></img>
      </a>
    </div>
  )
}
