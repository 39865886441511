import React, { useContext } from 'react'
import { DataContext } from '../contexts/DataContext'
import Iframe from 'react-iframe'

export default function Contact() {
  const { contactData } = useContext(DataContext)

  return (
    <section id="contact">
      {/* <!-- Contact List --> */}
      <div className="section-seperator">
        <div className="content-lg container">
          <div className="row">
            {/* <!-- Contact List --> */}
            <div className="col-sm-4 sm-margin-b-50">
              <h3>{contactData.office.title}<span className="text-uppercase margin-l-20"></span></h3>
              <ul className="list-unstyled contact-list">
                {contactData.office.addresses.map((address, index) => {
                  return (
                    <li key={index}>
                      <i className="margin-r-10 color-base icon-map"></i>
                      <a href={address.link}>
                        <br></br><b>{address.text[0]}</b>
                        <br></br>{address.text[1]}
                        <br></br>{address.text[2]}
                        <br></br>{address.text[3]}
                      </a>
                    </li>
                  )
                })}
              </ul>
            </div>
            {/* <!-- End Contact List --> */}

            {/* <!-- Contact List --> */}
            <div className="col-sm-4 sm-margin-b-50">
              <h3>{contactData.phone.title}<span className="text-uppercase margin-l-20"></span></h3>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incdidunt ut laboret dolor magna ut consequat siad esqudiat dolor</p> */}
              <ul className="list-unstyled contact-list">
                <li>
                  <i className="margin-r-10 color-base icon-call-out"></i>
                  <div>
                    <a href={contactData.phone.phoneNumberLink}>{contactData.phone.phoneNumber}</a>
                  </div>
                </li>
                <li>
                  <i className="margin-r-10 color-base">{contactData.phone.faxIcon}</i>
                  <div>{contactData.phone.faxNumber}</div>
                </li>
              </ul>
            </div>
            {/* <!-- End Contact List --> */}

            {/* <!-- Contact List --> */}
            <div className="col-sm-4 sm-margin-b-50">
              <h3>{contactData.email.title}<span className="text-uppercase margin-l-20"></span></h3>
              {/* <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed tempor incdidunt ut laboret dolor magna ut consequat siad esqudiat dolor</p> */}
              <ul className="list-unstyled contact-list">
                <li>
                  <i className="margin-r-10 color-base icon-envelope"></i>
                  <div>
                    <a href="mailto: office@shgz.co.il">office@shgz.co.il</a>
                  </div>
                </li>
              </ul>
            </div>
            {/* <!-- End Contact List --> */}
          </div>
          {/* <!--// end row --> */}
        </div>
      </div>
      {/* <!-- End Contact List --> */}

      {/* <!-- Google Map --> */}
      {/* <div id="map" className="map height-300"></div> */}
      {/* <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3380.961890394211!2d34.78386951547286!3d32.070279481191164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d4b9d680ff7c7%3A0x7b569c8815ba00a4!2sHaArba&#39;a%20St%2028%2C%20Tel%20Aviv-Yafo!5e0!3m2!1sen!2sil!4v1621716058265!5m2!1sen!2sil" */}
      <Iframe url="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=%D7%94%D7%90%D7%A8%D7%91%D7%A2%D7%94%2028%20%D7%AA%D7%9C%20%D7%90%D7%91%D7%99%D7%91%20%D7%99%D7%A4%D7%95+(SHGZ)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
        width="100%"
        height="300px"
        id="mapId"
        className="office-map"
        position="relative"
        frameBorder="0" />
    </section>

  )
}
