// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
const firebaseConfig = {
  // ...
  apiKey: "AIzaSyDSjy1LfDeqGWfLfWsuAu67rKnFJNK_PUE",
  authDomain: "sahai-harel-co.firebaseapp.com",
  databaseURL: "https://sahai-harel-co-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "sahai-harel-co",
  storageBucket: "sahai-harel-co.appspot.com",
  messagingSenderId: "462043427232",
  appId: "1:462043427232:web:41a691ec71d4951a3cf4c5",
  measurementId: "G-5H7EQ3F0E7"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore()
