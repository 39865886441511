import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Header from './components/Header'
import Publications from './components/Publications';
import Services from './components/Services';
import Slider from './components/Slider';
import Team from './components/Team';
import ArticlesContextProvider from './contexts/ArticlesContext';
import DataContextProvider from './contexts/DataContext';
import ErrorBoundary from './utils/ErrorBoundary'

function App() {
  return (
    <DataContextProvider>
      <Header />
      <Slider />
      <Services />
      <About />
      <Team />
      <ErrorBoundary>
        <ArticlesContextProvider>
          <Publications />
        </ArticlesContextProvider>
      </ErrorBoundary>
      <Contact />
      <Footer />
    </DataContextProvider>
  );
}

export default App;
